<template>
  <div>
    <c-tab
      type="horizon"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :dense="true"
      :height="tabHeight"
      :tabOptions.sync="tabOptions"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :chemData.sync="chemData"
          :contentHeight.sync="contentHeight"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'chem-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        tabName: '',
        subtabName: '',
        plantCd: null,
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'chemInfo',
      chemData: {
        plantCd: null,  // 사업장코드
        mdmChemMaterialId: '',  // 화학자재 일련번호
        materialCd: '',  // 자재코드
        mdmChemMaterialName: '',  // 화학자재 품명
        mdmChemReviewId: '',  // 사전검토 일련번호
        deliveryVendorCd: '',  // 납품업체 코드
        mfgVendorCd: '',  // 제조업체 코드
        psmFlag: 'N',  // PSM 대상 여부
        usageCd: '',  // 용도-회사별 공통코드
        usageEtc: '',  // 용도 기타
        msdsNo: '',  // MSDS번호
        casNo: '',  // MSDS번호
        inUnitCd: null,  // 입고단위
        inConversonFactor: '',  // 입고 환산계수
        inConversonUnitCd: null,  // 입고 환산단위
        specificGravity: '',  // 비중
        propertiesStateCd: null,  // 성상
        freezingPoint: '',  // 어는점
        boilPoint: '',  // 끓는점
        flashPoint: '',  // 인화점
        firePoint: '',  // 발화점
        licensingFlag: 'N',  // 인허가대상 물질 여부 Y/N
        dangerFlag: 'N',  // 위험물 여부Y/N
        makeFlag: 'N',  // 제품분류(제조)
        impFlag: 'N',  // 제품분류(수입)
        buyFlag: 'N',  // 제품분류(구매)
        usingFlag: 'N',  // 제품분류(사용)
        expFlag: 'N',  // 제품분류(수출)
        salesFlag: 'N',  // 제품분류(판매)
        toxicPoisonFlag: 'N',  // 유해화학물질포함여부(유독물질)
        toxicPermitFlag: 'N',  // 유해화학물질포함여부(허가물질)
        toxicLimitFlag: 'N',  // 유해화학물질포함여부(제한물질)
        toxicProhibitFlag: 'N',  // 유해화학물질포함여부(금지물질)
        remarks: '',  // 비고
        useFlag: 'Y',  // 사용여부
        unusedReason: '',  // 미사용사유
        preMdmChemMaterialId: '',  // 이전화학자재 일련번호
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        depts: '', // 사용반
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        productCategoryCd: null,  // 제품구분
        keepFacilityFlag: 'N',  // 보관시설 유무
        maxKeepAmount: '',  // 최대보관량
        storageFacilityFlag: 'N',  // 저장시설 유무
        maxStorageAmount: '',  // 최대저장량
        manufacturingFacilitiesFlag: 'N',  // 제조 사용시설 여부
        nanoManufacturingFlag: 'N',  // 나노물질 제조 여부
        nanoIncomeFlag: 'N',  // 나노물질 수입 여부
        nanoNoneApplicableFlag: 'N',  // 나노물질 해당없음 여부
        nanoUnknownFlag: 'N',  // 나노물질 모름 여부
        inputDissolutionTank: '',  // 투입 용해조
        productManufClassCd: null,  // 제품제조구분 코드
        chemIngredientsCd: '',  // 구성성분 작성근거
        mixFlag: 'N',  // 물질구성(단일 N/혼합 Y)
      },
      editable: true,
      detailUrl: '',
      tabOptions: {
        chemPsm: {
          tabLabelStyle: ''
        },
        chemGhs: {
          tabLabelStyle: ''
        },
      }
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    tabItems() {
      let _items = [ { name: 'chemInfo', icon: 'info', label: '화학자재 기본정보', component: () => import(`${'./chemInfo.vue'}`) }]
      if (this.popupParam.mdmChemMaterialId != '') {
          _items.push({ name: 'chemEtc', icon: 'settings_applications', label: '화학자재 상세정보', component: () => import(`${'./chemEtc.vue'}`) });
          _items.push({ name: 'chemMsds', icon: 'security', label: '구성물질 정보/규제', component: () => import(`${'./chemComponents.vue'}`) });
          _items.push({ name: 'chemGhs', icon: 'gavel', label: '제품별 GHS정보', component: () => import(`${'./chemGhs.vue'}`) });
          _items.push({ name: 'chemPsm', icon: 'warning_amber', label: '유해화학물질(PSM) 정보', component: () => import(`${'./chemPsm.vue'}`) });
      }
      return _items;
    } 
  },
  methods: {
    init() {
      if (this.popupParam.tabName) {
        this.tab = this.popupParam.tabName;
      }
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.mam.chem.get.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.mdmChemMaterialId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.mdmChemMaterialId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.chemData, _result.data);

          this.$set(this.tabOptions['chemPsm'], 'tabLabelStyle', this.chemData.psmFlag != 'Y' ? 'text-decoration-line:line-through;text-decoration-color:#C10015' : '')
          this.$set(this.tabItems[this.$_.findIndex(this.tabItems, { name: 'chemPsm' })], 'disabled', this.chemData.psmFlag != 'Y' ? true : false)

          // this.$set(this.tabOptions['chemGhs'], 'tabLabelStyle', this.chemData.ghsFlag != 'Y' ? 'text-decoration-line:line-through;text-decoration-color:#C10015' : '')
          // this.$set(this.tabItems[this.$_.findIndex(this.tabItems, { name: 'chemGhs' })], 'disabled', this.chemData.ghsFlag != 'Y' ? true : false)

          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      }
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>